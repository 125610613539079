import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Col, Form, Modal, Row, Spin, UploadFile } from 'antd';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useGetGameInfo } from 'hooks/games/useGetGameInfo';
import usePlayerCallback from 'hooks/player/usePlayerCallback';
import { ISupportedToken } from 'hooks/supported-token/useGetListSupportedTokenForScroll';
import { UploadFileDTO } from 'interfaces/dto/UploadFileDTO';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import {
  addNewGameVersion,
  ICheckUsernameExists,
  removeOldGameVersion,
  setActiveGameVersion,
  updateGameDetail,
} from 'services/api';
import { dataURLToBlob } from 'utils/image';

import { ImageCropperWrapper } from '../IntegrateGame/styled';
import { StyledForm, SubmitButton, ThemedModal, Wrapper } from '../styled';
import {
  AddNewVersionWrapper,
  AddVersionBodyWrapper,
  BackButton,
  BackgroundGameImage,
  BuildFileButton,
  BuildFileText,
  BuildFileWrapper,
  CheckBoxStyled,
  CheckBoxText,
  CheckBoxWrapper,
  DangerButton,
  DeleteContentWrapper,
  DeleteThemedModal,
  DividerStyled,
  FormItemInput,
  FormItemWrapper,
  GameReleaseButtonWrapper,
  GameReleaseTableWrapper,
  GameReleaseWrapper,
  GroupTitleStyled,
  HeaderContainer,
  HeaderTitle,
  InputAddVersionField,
  InputField,
  InputWrapper,
  LabelStyled,
  PrimaryButton,
  SelectStyled,
  StatusText,
  StyledTextArea,
  TextField,
  UploadDragger,
  UploadFileZipWrapper,
  UploadText,
  WarningStyled,
  WarningWrapper,
} from './styled';

import 'cropperjs/dist/cropper.css';

interface IUpdateGameModalProps {
  gameId: string;
  onRefresh: () => void;
}

export const UpdateGameModal: React.FC<IUpdateGameModalProps> = ({
  gameId,
  onRefresh,
}: IUpdateGameModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const inputRef = useRef(null);

  const [gameName, setGameName] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [buildFile, setBuildFile] = useState<UploadFileDTO>();
  const [backgroundImg, setBackgroundImg] = useState<UploadFileDTO>();
  const [previewBackgroundImg, setPreviewBackgroundImg] = useState('');
  const [savedBackgroundImg, setSavedBackgroundImg] = useState('');

  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [visiblePopup, setVisiblePopup] = useState<boolean>(false);

  const [confirmDeleteVisible, setConfirmDeleteVisible] =
    useState<boolean>(false);
  const [confirmIndex, setConfirmIndex] = useState<number>(null);

  const [token, setToken] = useState<ISupportedToken>(undefined);
  const [entryFee, setEntryFee] = useState('');
  const [provider, setProvider] = useState('');
  const [treasury, setTreasury] = useState('');
  const [minLevel, setMinLevel] = useState<number>(0);
  const [maxLevel, setMaxLevel] = useState<number>(0);

  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [isStoshi, setIsStoshi] = useState<boolean>(false);
  const [isTestingGame, setIsTestingGame] = useState<boolean>(false);
  const [levelOwnerProfit, setLevelOwnerProfit] = useState<number>(0);
  const [providerProfit, setProviderProfit] = useState<number>(0);
  const [thirdPartyProfit, setThirdPartyProfit] = useState<number>(0);
  const [affiliateProfit, setAffiliateProfit] = useState<number>(0);

  const { checkUsernameExists } = usePlayerCallback();

  const [providerExists, setProviderExists] =
    useState<ICheckUsernameExists>(null);
  const [checkingProvider, setCheckingProvider] = useState<boolean>(false);
  const [treasuryExists, setTreasuryExists] =
    useState<ICheckUsernameExists>(null);
  const [checkingTreasury, setCheckingTreasury] = useState<boolean>(false);

  useDebounce(
    () => {
      void (async () => {
        if (provider) {
          setCheckingProvider(true);
          const result = await checkUsernameExists(provider);
          setProviderExists(result);
          setCheckingProvider(false);
        }
      })();
    },
    500,
    [provider],
  );
  useDebounce(
    () => {
      void (async () => {
        if (treasury) {
          setCheckingTreasury(true);
          const result = await checkUsernameExists(treasury);
          setTreasuryExists(result);
          setCheckingTreasury(false);
        }
      })();
    },
    500,
    [treasury],
  );

  const onRefreshSuccess = () => {
    onRefresh();
  };

  const isOpen = useModalIsOpen(ApplicationModal.UPDATE_GAME);
  const integrateGameToggle = useToggleModal(ApplicationModal.UPDATE_GAME);
  const [form] = Form.useForm();

  // const reviewToggle = useToggleModal(ApplicationModal.REVIEW_INTEGRATE);

  const onFinish = async (values: any) => {
    if (ableToReview) {
      setConfirmVisible(true);
    }
    // if (values) {
    //   try {
    //     // reviewToggle();
    //   } catch (e) {
    //     console.log(e.message);
    //     setLoading(false);
    //   }
    // }
  };

  const handleProviderProfitChange = value => {
    value = value?.toString();
    setProviderProfit(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + 3)
        : value,
    );
  };

  const handleLevelOwnerProfitChange = value => {
    value = value?.toString();
    setLevelOwnerProfit(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + 3)
        : value,
    );
  };

  const handleTreasuryProfitChange = value => {
    value = value?.toString();
    setThirdPartyProfit(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + 3)
        : value,
    );
  };

  const handleAffiliateProfitChange = value => {
    value = value?.toString();
    setAffiliateProfit(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + 3)
        : value,
    );
  };

  const handleEntryFeeChange = value => {
    if (token) {
      value = value?.toString();
      setEntryFee(
        value && value.indexOf('.') >= 0
          ? value.slice(0, value.indexOf('.') + Number(token.decimals) + 1)
          : value,
      );
    } else {
      setEntryFee(value);
    }
  };

  const handleMinLevelChange = value => {
    value = value?.toString();
    setMinLevel(value);
  };

  const handleMaxLevelChange = value => {
    value = value?.toString();
    setMaxLevel(value);
  };

  const handleKeyDown = e => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const validKey = /^\d+$/.test(keyValue);
    if (!validKey) {
      e.preventDefault();
    }
  };

  const handleUpdateClick = () => {
    setConfirmVisible(true);
  };

  const onCloseModal = () => {
    integrateGameToggle();
    form.resetFields();
    setGameName('');
    setDescription('');
    setTags([]);

    handleBackgroundImg();
    handleFileZip();
    setMinLevel(0);
    setMaxLevel(0);
    setToken(undefined);
    setEntryFee('');
    setProvider('');
    setTreasury('');
    setLevelOwnerProfit(0);
    setProviderProfit(0);
    setThirdPartyProfit(0);
    setAffiliateProfit(0);
    setIsExternal(false);
    setIsStoshi(false);
  };

  const platformProfit = useMemo(() => {
    return (
      100 -
      levelOwnerProfit -
      (isExternal ? providerProfit : 0) -
      (isStoshi ? thirdPartyProfit : 0) -
      affiliateProfit
    );
  }, [
    levelOwnerProfit,
    providerProfit,
    thirdPartyProfit,
    isExternal,
    isStoshi,
    affiliateProfit,
  ]);

  const [refresh, setRefresh] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const { gameInfo } = useGetGameInfo(gameId, isOpen, refresh);

  const updatedData = useMemo(() => {
    const formData = new FormData();

    formData.append(
      'defaultEntryFee',
      Number(entryFee) > 0
        ? parseUnits(entryFee, gameInfo?.tokenInfo?.decimals || 18).toString()
        : '0',
    );

    if (isExternal) {
      formData.append('providerUsername', providerExists?.player?.username);
      formData.append(
        'providerFeeRate',
        (Number(providerProfit) * 100).toString(),
      );
    }

    if (isStoshi) {
      formData.append('treasuryUsername', treasuryExists?.player?.username);
      formData.append(
        'treasuryFeeRate',
        (Number(thirdPartyProfit) * 100).toString(),
      );
    }

    formData.append('isTestingGame', isTestingGame as any);

    formData.append(
      'affiliateFeeRate',
      (Number(affiliateProfit) * 100).toString(),
    );
    formData.append(
      'levelOwnerFeeRate',
      (Number(levelOwnerProfit) * 100).toString(),
    );
    formData.append(
      'platformFeeRate',
      (Number(platformProfit) * 100).toString(),
    );
    formData.append('name', gameName);
    formData.append('tags', tags as any);
    formData.append('description', JSON.stringify(description));
    formData.append('minLevel', Number(minLevel).toString());
    formData.append('maxLevel', Number(maxLevel).toString());

    if (backgroundImg && savedBackgroundImg) {
      const blob = dataURLToBlob(savedBackgroundImg);
      const { type, name, uid, lastModifiedDate } = backgroundImg as UploadFile;
      const croppedBackgroundImg = new File([blob as BlobPart], name, {
        type,
        lastModified: lastModifiedDate?.getTime(),
      });
      Object.assign(croppedBackgroundImg, { uid });
      Object.assign(croppedBackgroundImg, { url: savedBackgroundImg });
      Object.assign(croppedBackgroundImg, { preview: savedBackgroundImg });
      Object.assign(croppedBackgroundImg, { thumbUrl: savedBackgroundImg });

      formData.append('backgroundImg', croppedBackgroundImg);
    }

    return formData;
  }, [
    entryFee,
    isExternal,
    isStoshi,
    isTestingGame,
    thirdPartyProfit,
    providerProfit,
    levelOwnerProfit,
    platformProfit,
    providerExists,
    treasuryExists,
    gameInfo,
    affiliateProfit,
    gameName,
    backgroundImg,
    savedBackgroundImg,
    tags,
    description,
    minLevel,
    maxLevel,
  ]);

  const ableToReview = useMemo(() => {
    const isValid =
      gameName &&
      description &&
      affiliateProfit &&
      platformProfit &&
      levelOwnerProfit &&
      entryFee &&
      Number(platformProfit) > 0 &&
      Number(platformProfit) <= 100 &&
      Number(levelOwnerProfit) > 0 &&
      Number(levelOwnerProfit) <= 100 &&
      Number(affiliateProfit) > 0 &&
      Number(affiliateProfit) <= 100 &&
      Number(entryFee) >= 0 &&
      minLevel &&
      maxLevel &&
      Number(minLevel) >= 0 &&
      Number(maxLevel) >= 0 &&
      Number(minLevel) <= Number(maxLevel);
    if (isExternal) {
      return (
        isValid &&
        provider &&
        !checkingProvider &&
        providerExists &&
        providerExists.player?.username === provider &&
        providerProfit &&
        Number(providerProfit) > 0 &&
        Number(providerProfit) <= 100
      );
    } else {
      if (isStoshi) {
        return (
          isValid &&
          thirdPartyProfit &&
          treasury &&
          !checkingTreasury &&
          treasuryExists &&
          treasuryExists.player?.username === treasury &&
          Number(thirdPartyProfit) > 0 &&
          Number(thirdPartyProfit) <= 100
        );
      }
      return isValid;
    }
  }, [
    gameName,
    description,
    affiliateProfit,
    platformProfit,
    levelOwnerProfit,
    entryFee,
    minLevel,
    maxLevel,
    isExternal,
    provider,
    checkingProvider,
    providerExists,
    providerProfit,
    isStoshi,
    thirdPartyProfit,
    treasury,
    checkingTreasury,
    treasuryExists,
  ]);

  useEffect(() => {
    if (gameInfo) {
      setIsExternal(gameInfo.isExternal);
      setIsStoshi(gameInfo.isStoshi);
      setIsTestingGame(gameInfo.isTestingGame);
      if (gameInfo.levelOwnerFeeRate) {
        setLevelOwnerProfit(gameInfo.levelOwnerFeeRate / 100);
      }
      if (gameInfo.treasuryFeeRate) {
        setThirdPartyProfit(gameInfo.treasuryFeeRate / 100);
      }
      if (gameInfo.affiliateFeeRate) {
        setAffiliateProfit(gameInfo.affiliateFeeRate / 100);
      }
      if (gameInfo.providerFeeRate) {
        setProviderProfit(gameInfo.providerFeeRate / 100);
      }
      handleEntryFeeChange(
        formatUnits(gameInfo.defaultEntryFee, gameInfo.tokenInfo.decimals),
      );
      setProvider(gameInfo.providerInfo?.username);
      setTreasury(gameInfo.treasuryInfo?.username);
      setGameName(gameInfo.name);
      setDescription(JSON.parse(gameInfo.description));
      setTags(gameInfo.tags);
      setMinLevel(gameInfo.minLevel);
      setMaxLevel(gameInfo.maxLevel);
    }
  }, [gameInfo]);

  const toggleGameDetails = useToggleModal(ApplicationModal.GAME_DETAILS);

  const handleModalOk = async () => {
    setConfirmVisible(false);
    setLoading(true);
    const response = await updateGameDetail(gameId, updatedData);
    if (response?.success) {
      toast.success('Update game detail success!!');
      onRefresh();
      handleBackgroundImg();
      toggleGameDetails();
    } else toast.error(response?.message);

    setLoading(false);
    onRefresh();
  };

  const columns = [
    {
      title: 'Version name',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        const isActive = record.version === gameInfo.activeVersion;
        return (
          <StatusText active={isActive}>
            {isActive ? 'Active' : 'Inactive'}
          </StatusText>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        const isActive = record.status === 'Active';
        return (
          <GameReleaseButtonWrapper>
            <PrimaryButton
              disabled={isActive}
              onClick={() => {
                setLoadingTable(true);
                handleActivateGameVersion(index);
              }}
            >
              Activate
            </PrimaryButton>
            <DangerButton
              disabled={isActive}
              onClick={() => {
                setConfirmIndex(index);
                setConfirmDeleteVisible(true);
              }}
            >
              Delete
            </DangerButton>
          </GameReleaseButtonWrapper>
        );
      },
    },
  ];

  const gameVersionData = useMemo(
    () =>
      gameInfo?.supportedVersions.map((version, index) => ({
        key: index.toString(),
        version: version.version,
        status:
          gameInfo.activeVersion === version.version ? 'Active' : 'Inactive',
      })),
    [gameInfo?.activeVersion, gameInfo?.supportedVersions],
  );

  const [version, setVersion] = useState('');

  const validateInput = value => {
    const regex = /^[0-9-.]*$/;
    return regex.test(value);
  };

  const openAddNewVersionPopup = () => {
    setVisiblePopup(true);
  };

  const handleAddNewVersion = async () => {
    setLoadingTable(true);

    const formData = new FormData();

    formData.append('gameId', gameId);
    formData.append('version', version);
    formData.append('buildFile', buildFile);

    const addRes = await addNewGameVersion(formData);

    if (addRes?.success) {
      setVisiblePopup(false);
      setVersion('');
      setBuildFile(undefined);
      toast.success('You have successfully added a new game version');
      onRefresh();
      setRefresh(!refresh);
    } else {
      toast.error(addRes?.message || 'Adding the game version failed!');
    }

    setLoadingTable(false);
  };

  const handleActivateGameVersion = async index => {
    setLoadingTable(true);

    const activeRes = await setActiveGameVersion(
      gameId,
      gameInfo.supportedVersions[index]?.version,
    );
    if (activeRes?.success) {
      toast.success('You have successfully activated game version');
      onRefresh();
      setRefresh(!refresh);
    } else toast.error(activeRes?.message || 'Activate game version failed!!');

    setLoadingTable(false);
  };

  const handleDeleteGameVersion = async () => {
    setConfirmDeleteVisible(false);
    setLoadingTable(true);

    const removeRes = await removeOldGameVersion(
      gameId,
      gameInfo.supportedVersions[confirmIndex]?.version,
    );
    if (removeRes?.success) {
      toast.success('You have successfully removed game version');
      onRefresh();
      setRefresh(!refresh);
    } else toast.error(removeRes?.message || 'Remove game version failed!!');

    setLoadingTable(false);
  };

  const handleBackButton = () => {
    toggleGameDetails();
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => resolve(e.target ? e.target.result : null);
      reader.onerror = error => reject(error);
    });
  };

  const uploadImageProps = {
    name: 'BackgroundImg',
    multiple: false,
    beforeUpload: async (_, fileList) => {
      handleBackgroundImg();

      const files = [];
      for (const file of fileList) {
        const slip = file;
        if (slip && !slip.url && !slip.preview) {
          const preview = (await getBase64(slip))?.toString() || '';
          slip.preview = preview;
          slip.url = preview;
          slip.thumbUrl = preview;
          setPreviewBackgroundImg(preview);
        }
        files.push(slip);
      }
      setBackgroundImg(files[0]);
      form.setFieldValue('BackgroundImg', files);
      await form.validateFields();
      return false;
    },
    fileList: backgroundImg ? [backgroundImg] : [],
    accept: 'image/jpg,image/jpeg,image/png,image/JPG,image/JPEG,image/PNG',
    onPreview: async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file);
      }
      setPreviewBackgroundImg(file.preview);
    },
    showUploadList: false,
    maxCount: 1,
  };

  const uploadFileZipProps = {
    name: 'FileZip',
    multiple: false,
    beforeUpload: async file => {
      setBuildFile(file);
      form.setFieldValue('FileZip', [file]);
      await form.validateFields();
      return false;
    },
    fileList: buildFile ? [buildFile] : [],
    accept: '.zip,application/zip,application/x-zip-compressed',
    showUploadList: false,
    maxCount: 1,
  };

  const handleEditClick = e => {
    if (backgroundImg && !savedBackgroundImg) {
      e.stopPropagation();
      setVisible(true);
    }
  };

  useEffect(() => {
    if (backgroundImg && !visible) {
      setVisible(true);
    }
  }, [backgroundImg]);

  const handleCancel = () => {
    if (backgroundImg && !savedBackgroundImg) {
      handleBackgroundImg();
    }
    setVisible(false);
  };

  const handleSave = () => {
    const canvasScaled = inputRef?.current?.cropper?.getCroppedCanvas();
    const dataURL = canvasScaled?.toDataURL();
    if (dataURL) {
      setSavedBackgroundImg(dataURL);
    } else {
      toast.error('Crop image failed. Please upload and crop again');
      handleBackgroundImg();
    }

    setVisible(false);
  };

  const handleBackgroundImg = () => {
    setBackgroundImg(undefined);
    setPreviewBackgroundImg(undefined);
    setSavedBackgroundImg(undefined);
    form.setFieldValue('BackgroundImg', []);
    form.validateFields();
  };

  const handleFileZip = () => {
    setBuildFile(undefined);
    form.setFieldValue('FileZip', []);
    form.validateFields();
  };

  const ableToAddNewVersion = useMemo(() => {
    const isValid = version && buildFile;
    return isValid;
  }, [version, buildFile]);

  const modalHeader = (
    <HeaderContainer>
      <BackButton onClick={handleBackButton} disabled={loading}>
        <ArrowLeftOutlined />
      </BackButton>
      <HeaderTitle>Update Your Game</HeaderTitle>
    </HeaderContainer>
  );

  return (
    <>
      <ThemedModal
        title={modalHeader}
        open={isOpen}
        onCancel={onCloseModal}
        footer={false}
        width={870}
      >
        <StyledForm
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          labelcolor="black"
          form={form}
        >
          <FormItemWrapper>
            <GroupTitleStyled>Game Info</GroupTitleStyled>
            <Row gutter={8}>
              <Col xs={24}>
                <FormItemInput className="mt-2" name={['game-name']}>
                  <InputWrapper>
                    <LabelStyled>Game name</LabelStyled>
                    <InputField
                      allowClear
                      type={'string'}
                      placeholder="Enter here"
                      onChange={e => setGameName(e.target.value)}
                      disabled={loading}
                      value={gameName}
                    />
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24}>
                <FormItemInput className="mt-2" name={['game-description']}>
                  <InputWrapper>
                    <LabelStyled>Description</LabelStyled>
                    <StyledTextArea
                      allowClear
                      placeholder="Enter here"
                      onChange={e => {
                        setDescription(e.target.value);
                      }}
                      disabled={loading}
                      value={description}
                    />
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-2" name={['game-tags']}>
                  <InputWrapper>
                    <LabelStyled>Tags</LabelStyled>
                    <SelectStyled
                      placeholder="Enter here"
                      disabled={loading}
                      mode={'tags'}
                      onSelect={(value: string) => {
                        setTags([...tags, value]);
                      }}
                      onDeselect={value => {
                        setTags(tags.filter(i => i !== value));
                      }}
                      value={tags}
                    />
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24}>
                <FormItemInput className="mt-2" name={['game-cover-img']}>
                  <InputWrapper
                    style={{
                      justifyContent: 'space-between',
                      maxHeight: 'unset',
                    }}
                  >
                    <LabelStyled>Background image</LabelStyled>
                    <UploadDragger {...uploadImageProps} disabled={loading}>
                      {backgroundImg || gameInfo?.backgroundImgUrl ? (
                        <BackgroundGameImage
                          onClick={handleEditClick}
                          src={
                            backgroundImg
                              ? savedBackgroundImg
                              : gameInfo?.backgroundImgUrl
                          }
                        >
                          {backgroundImg && (
                            <CloseCircleOutlined
                              onClick={e => {
                                e.stopPropagation();
                                handleBackgroundImg();
                              }}
                            />
                          )}
                        </BackgroundGameImage>
                      ) : (
                        <UploadText onClick={handleEditClick}>
                          Upload here
                        </UploadText>
                      )}
                    </UploadDragger>
                  </InputWrapper>
                  <Modal
                    title="Edit background image"
                    visible={visible}
                    onCancel={handleCancel}
                    onOk={handleSave}
                    okText="Save"
                    cancelText="Cancel"
                    bodyStyle={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {/* <AvatarEditor
                      ref={inputRef}
                      image={savedBackgroundImg || previewBackgroundImg}
                      width={160}
                      height={200}
                      border={50}
                      color={[255, 255, 255, 0.6]}
                      scale={1}
                      rotate={0}
                    /> */}
                    <ImageCropperWrapper>
                      <Cropper
                        ref={inputRef}
                        aspectRatio={8 / 10}
                        src={savedBackgroundImg || previewBackgroundImg}
                        viewMode={1}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={false}
                        dragMode={'move'}
                        checkCrossOrigin={false}
                        center={false}
                        highlight={false}
                        cropBoxResizable={false}
                        zoomable={false}
                      />
                    </ImageCropperWrapper>
                  </Modal>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-2" name={['min-level']}>
                  <InputWrapper>
                    <LabelStyled>Min Level</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      min={0}
                      placeholder="Enter here"
                      onChange={e => handleMinLevelChange(e.target.value)}
                      disabled={loading}
                      onKeyDown={handleKeyDown}
                      value={minLevel || ''}
                    />
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-2" name={['max-level']}>
                  <InputWrapper>
                    <LabelStyled>Max Level</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      min={0}
                      placeholder="Enter here"
                      onChange={e => handleMaxLevelChange(e.target.value)}
                      disabled={loading}
                      onKeyDown={handleKeyDown}
                      value={maxLevel || ''}
                    />
                  </InputWrapper>
                </FormItemInput>
                {minLevel &&
                  maxLevel &&
                  Number(minLevel) > Number(maxLevel) && (
                    <WarningStyled>
                      **Max Level cannot be less than Min Level
                    </WarningStyled>
                  )}
              </Col>
            </Row>
            <DividerStyled />
            <GroupTitleStyled>Game Release</GroupTitleStyled>
            <Row gutter={8}>
              <GameReleaseWrapper>
                <GameReleaseTableWrapper
                  columns={columns}
                  dataSource={gameVersionData}
                  pagination={false}
                  loading={loadingTable}
                />
                <PrimaryButton
                  disabled={
                    gameVersionData?.length === 3 || loading || loadingTable
                  }
                  onClick={openAddNewVersionPopup}
                  style={{ margin: '16px 0 32px 64px' }}
                >
                  Add new version
                </PrimaryButton>
              </GameReleaseWrapper>
              <ThemedModal
                visible={visiblePopup}
                onOk={handleAddNewVersion}
                onCancel={() => setVisiblePopup(false)}
                okText="Add"
                cancelText="No"
                okButtonProps={{
                  loading: loadingTable,
                  disabled: !ableToAddNewVersion,
                }}
                // loading={loadingTable}
                width={400}
              >
                <AddNewVersionWrapper>
                  <GroupTitleStyled>Add new Game Version</GroupTitleStyled>
                  <AddVersionBodyWrapper>
                    <InputAddVersionField
                      allowClear
                      type={'string'}
                      value={version}
                      disabled={loadingTable}
                      placeholder="Version name: Ex: 1.0.0"
                      onChange={e => {
                        const { value } = e.target;
                        if (validateInput(value)) {
                          setVersion(value);
                        }
                      }}
                    />
                    <BuildFileWrapper>
                      <BuildFileText>Build file</BuildFileText>

                      <UploadDragger
                        {...uploadFileZipProps}
                        disabled={loadingTable}
                      >
                        {buildFile ? (
                          <UploadFileZipWrapper>
                            {buildFile?.name}
                            {!loadingTable && (
                              <CloseCircleOutlined
                                onClick={e => {
                                  e.stopPropagation();
                                  handleFileZip();
                                }}
                              />
                            )}
                          </UploadFileZipWrapper>
                        ) : (
                          <BuildFileButton>Upload</BuildFileButton>
                        )}
                      </UploadDragger>
                    </BuildFileWrapper>
                  </AddVersionBodyWrapper>
                </AddNewVersionWrapper>
              </ThemedModal>
              <DeleteThemedModal
                visible={confirmDeleteVisible}
                onOk={handleDeleteGameVersion}
                onCancel={() => {
                  setLoadingTable(false);
                  setConfirmDeleteVisible(false);
                  setConfirmIndex(null);
                }}
                okText={'Yes permanently delete'}
                cancelText="No, come back"
                width={400}
                style={{ marginTop: '50px' }}
              >
                {confirmIndex !== null && (
                  <DeleteContentWrapper>
                    <WarningWrapper>
                      <WarningTwoTone twoToneColor="#fcd00d" />
                      Warning!
                      <WarningTwoTone twoToneColor="#fcd00d" />
                    </WarningWrapper>
                    <p>
                      Are you sure to delete the version{' '}
                      <b>{gameInfo.supportedVersions[confirmIndex]?.version}</b>{' '}
                      of the game <b>{gameName}</b>?
                    </p>
                  </DeleteContentWrapper>
                )}
              </DeleteThemedModal>
              <CheckBoxWrapper
                className="mt-4"
                param={isTestingGame ? isTestingGame.toString() : undefined}
              >
                <CheckBoxText>Test mode</CheckBoxText>
                <CheckBoxStyled
                  param={isTestingGame ? isTestingGame.toString() : undefined}
                  checked={isTestingGame}
                  onChange={event => {
                    setIsTestingGame(event.target.checked);
                  }}
                />
              </CheckBoxWrapper>
            </Row>
            <DividerStyled />
            <GroupTitleStyled>Distribution Config</GroupTitleStyled>
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={14}>
                {isExternal && (
                  <>
                    <FormItemInput className="mt-2" name={['provider']}>
                      <InputWrapper>
                        <LabelStyled>Provider username</LabelStyled>
                        <Spin spinning={checkingProvider}>
                          <InputField
                            allowClear
                            type={'string'}
                            placeholder="Enter here"
                            onChange={e => setProvider(e.target.value)}
                            disabled={loading}
                            value={provider}
                          />
                        </Spin>
                      </InputWrapper>
                    </FormItemInput>
                    {provider &&
                      !checkingProvider &&
                      providerExists &&
                      !providerExists?.isExisting && (
                        <WarningStyled>
                          **Username does not exists
                        </WarningStyled>
                      )}
                  </>
                )}
                {isStoshi && (
                  <>
                    <FormItemInput className="mt-2" name={['treasury']}>
                      <InputWrapper>
                        <LabelStyled>Treasury username</LabelStyled>
                        <Spin spinning={checkingTreasury}>
                          <InputField
                            allowClear
                            type={'string'}
                            placeholder="Enter here"
                            onChange={e => setTreasury(e.target.value)}
                            disabled={loading}
                            value={treasury}
                          />
                        </Spin>
                      </InputWrapper>
                    </FormItemInput>
                    {treasury &&
                      !checkingTreasury &&
                      treasuryExists &&
                      !treasuryExists?.isExisting && (
                        <WarningStyled>
                          **Username does not exists
                        </WarningStyled>
                      )}
                  </>
                )}
                <FormItemInput className="mt-2" name={['default-fee']}>
                  <InputWrapper>
                    <LabelStyled>Default entry fee</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      placeholder="0"
                      min={0}
                      step={0.00001}
                      onChange={e => handleEntryFeeChange(e.target.value)}
                      disabled={loading}
                      value={entryFee}
                    />
                  </InputWrapper>
                </FormItemInput>
                {entryFee && Number(entryFee) < 0 && (
                  <WarningStyled>
                    **Default entry fee must be greater than or equal to 0
                  </WarningStyled>
                )}
              </Col>
              <Col xs={24} sm={24} md={24} lg={10}>
                <FormItemInput className="mt-2" name={['level-owner-profit']}>
                  <InputWrapper>
                    <LabelStyled>Level owner profit</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      placeholder="0%"
                      min={0}
                      max={100}
                      step={0.01}
                      onChange={e =>
                        handleLevelOwnerProfitChange(e.target.value)
                      }
                      disabled={loading}
                      value={levelOwnerProfit || ''}
                    />
                  </InputWrapper>
                </FormItemInput>
                {!!levelOwnerProfit &&
                  (Number(levelOwnerProfit) <= 0 ||
                    Number(levelOwnerProfit) > 100) && (
                    <WarningStyled>
                      **Level owner profit must be greater than 0 and less than
                      100
                    </WarningStyled>
                  )}
                {isStoshi && (
                  <>
                    <FormItemInput className="mt-2" name={['treasury-profit']}>
                      <InputWrapper>
                        <LabelStyled>Third-party profit</LabelStyled>
                        <InputField
                          allowClear
                          type={'number'}
                          placeholder="0%"
                          min={0}
                          max={100}
                          step={0.01}
                          onChange={e =>
                            handleTreasuryProfitChange(e.target.value)
                          }
                          disabled={loading}
                          value={thirdPartyProfit || ''}
                        />
                      </InputWrapper>
                    </FormItemInput>
                    {!!thirdPartyProfit &&
                      (Number(thirdPartyProfit) <= 0 ||
                        Number(thirdPartyProfit) > 100) && (
                        <WarningStyled>
                          **Third party profit must be greater than 0 and less
                          than 100
                        </WarningStyled>
                      )}
                  </>
                )}
                {isExternal && (
                  <>
                    <FormItemInput className="mt-2" name={['provider-profit']}>
                      <InputWrapper>
                        <LabelStyled>Provider profit</LabelStyled>
                        <InputField
                          allowClear
                          type={'number'}
                          placeholder="0%"
                          min={0}
                          max={100}
                          step={0.01}
                          onChange={e =>
                            handleProviderProfitChange(e.target.value)
                          }
                          disabled={loading}
                          value={providerProfit || ''}
                        />
                      </InputWrapper>
                    </FormItemInput>
                    {!!providerProfit &&
                      (Number(providerProfit) <= 0 ||
                        Number(providerProfit) > 100) && (
                        <WarningStyled>
                          **Provider profit must be greater than 0 and less than
                          100
                        </WarningStyled>
                      )}
                  </>
                )}
                <FormItemInput className="mt-2" name={['affiliate-profit']}>
                  <InputWrapper>
                    <LabelStyled>Affiliate profit</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      placeholder="0%"
                      min={0}
                      max={100}
                      step={0.01}
                      onChange={e =>
                        handleAffiliateProfitChange(e.target.value)
                      }
                      disabled={loading}
                      value={affiliateProfit || ''}
                    />
                  </InputWrapper>
                </FormItemInput>
                {!!affiliateProfit &&
                  (Number(affiliateProfit) <= 0 ||
                    Number(affiliateProfit) > 100) && (
                    <WarningStyled>
                      **Affiliate profit must be greater than 0 and less than
                      100
                    </WarningStyled>
                  )}
                <FormItemInput className="mt-2" name={['platform-profit']}>
                  <InputWrapper>
                    <LabelStyled>Platform profit</LabelStyled>
                    <TextField flex="1">{platformProfit || 0}%</TextField>
                  </InputWrapper>
                </FormItemInput>
              </Col>
            </Row>
          </FormItemWrapper>

          <Wrapper>
            <SubmitButton
              type="primary"
              htmlType="submit"
              disabled={!ableToReview}
              // onClick={handleUpdateClick}
              loading={loading}
            >
              Update
            </SubmitButton>
          </Wrapper>
          <ThemedModal
            visible={confirmVisible}
            onOk={handleModalOk}
            onCancel={() => setConfirmVisible(false)}
            okText={'Update'}
            cancelText="No"
            width={400}
            style={{ marginTop: '50px' }}
          >
            <p>Do you want to update this game?</p>
          </ThemedModal>
        </StyledForm>
      </ThemedModal>
    </>
  );
};
